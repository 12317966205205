<svelte:options accessors tag="wh-chatbot-client-navigation" />

<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let caption = "";
    export let context = "";

    const dispatch = createEventDispatcher();
</script>

<a href={context}>{caption}</a>

<svelte:options accessors tag="wh-chatbot-client-handover" />

<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let caption = "";
    export let context = "";

    const dispatch = createEventDispatcher();

    function dispatchClick() {
        dispatch("handover", context);
    }
</script>

<button class="button-message" value={context} on:click={dispatchClick}>
    {caption}
</button>

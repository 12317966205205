<svelte:options accessors tag="wh-chatbot-client-image" />

<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let caption = "";
    export let context = "";

    const dispatch = createEventDispatcher();

    function dispatchClick() {
        dispatch("click", context);
    }
</script>

<img src={context} alt={caption} on:click={dispatchClick} />

<svelte:options accessors tag="wh-chatbot-client-video" />

<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let caption = "";
    export let context = "";

    const dispatch = createEventDispatcher();

    function dispatchClick() {
        dispatch("click", context);
    }
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<video src={context} title={caption} />

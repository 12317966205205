<svelte:options accessors tag="wh-chatbot-client-error" />

<script lang="ts">
    export let caption = "";
    export let context = "";
</script>

<div class="error" data-context={context}>{@html caption}</div>

<style>
    .error {
        background-color: #fff;
        color: #511;
        font-weight: 900;
    }
</style>
